import { fetcher } from '../fetcher';

const SCHEMAT_ENDPOINT = '/schemats';

export type AddSchematDetail = {
  schemat_id: number;
  week: number;
  study_day: number;
  visit_window: number;
  visit_name: string;
  schemat_screening_name_id: number;
};

export const schematsService = {
  storeSchemat: async (payload: AddSchematForm) => {
    return await fetcher(`${SCHEMAT_ENDPOINT}`, {
      method: 'POST',
      body: payload,
      schema: schematSchema,
    });
  },
  showSchemat: async (schemat_id: number) => {
    return await fetcher(`${SCHEMAT_ENDPOINT}/${schemat_id}`, {
      method: 'GET',
      schema: detailedSchematSchema,
    });
  },
  getSchemats: async () => {
    return await fetcher(`${SCHEMAT_ENDPOINT}`, {
      method: 'GET',
      schema: schematsSchema,
    });
  },
  updateSchemat: async (payload: {
    schemat_id: number;
    name: string;
    status: SchematsStatus;
  }) => {
    return await fetcher(`${SCHEMAT_ENDPOINT}/${payload.schemat_id}`, {
      method: 'PATCH',
      body: {
        name: payload.name,
        status: payload.status,
      },
    });
  },
  deleteSchemat: async (schemat_id: number) => {
    return await fetcher(`${SCHEMAT_ENDPOINT}/${schemat_id}`, {
      method: 'DELETE',
    });
  },
  deleteScreening: async (schemat_id: number, screening_id: number) => {
    return await fetcher(
      `${SCHEMAT_ENDPOINT}/${schemat_id}/screening-name/${screening_id}`,
      {
        method: 'DELETE',
      },
    );
  },
  updateScreening: async (payload: {
    schemat_id: number;
    name: string;
    screening_id: number;
  }) => {
    return await fetcher(
      `${SCHEMAT_ENDPOINT}/${payload.schemat_id}/screening-name/${payload.screening_id}`,
      {
        method: 'PATCH',
        body: {
          name: payload.name,
        },
      },
    );
  },
  deleteSchematDetails: async (
    schemat_id: number,
    schemat_details_id: number,
  ) => {
    return await fetcher(
      `${SCHEMAT_ENDPOINT}/${schemat_id}/details/${schemat_details_id}`,
      {
        method: 'DELETE',
      },
    );
  },
  updateSchematDetail: async (
    payload: Omit<AddSchematDetail, 'visit_name'> & {
      schemat_details_id: number;
      visit_name: string | null;
    },
  ) => {
    return await fetcher(
      `${SCHEMAT_ENDPOINT}/${payload.schemat_id}/details/${payload.schemat_details_id}`,
      {
        method: 'PATCH',
        body: {
          week: payload.week,
          study_day: payload.study_day,
          visit_window: payload.visit_window,
          visit_name: payload.visit_name,
          schemat_screening_name_id: payload.schemat_screening_name_id,
        },
      },
    );
  },
  storeScreening: async (payload: { schemat_id: number; name: string }) => {
    return await fetcher(
      `${SCHEMAT_ENDPOINT}/${payload.schemat_id}/screening-name`,
      {
        method: 'POST',
        body: {
          name: payload.name,
        },
        schema: storeScreeningResponseSchema,
      },
    );
  },
  storeSchematDetails: async (payload: AddSchematDetail) => {
    return await fetcher(`${SCHEMAT_ENDPOINT}/${payload.schemat_id}/details`, {
      method: 'POST',
      body: {
        week: payload.week,
        study_day: payload.study_day,
        visit_window: payload.visit_window,
        visit_name: payload.visit_name,
        schemat_screening_name_id: payload.schemat_screening_name_id,
      },
      schema: weekSchema,
    });
  },
};
