import { useQuery } from '@tanstack/vue-query';
import { schematsService } from '~/utils/api/schematsService';

export function useSchemats() {
  const search = ref('');

  const { data } = useQuery({
    queryKey: ['schemats'],
    queryFn: schematsService.getSchemats,
  });

  const searchedSchemats = computed(() => {
    if (!data.value) {
      return [];
    }

    if (search.value === '') {
      return data.value;
    }

    return data.value.filter((schema) =>
      schema.name
        .toLocaleLowerCase()
        .includes(search.value.toLocaleLowerCase()),
    );
  });

  const activeSchemats = computed(() =>
    searchedSchemats.value.filter((schema) => schema.status === 'active'),
  );

  function getOptionsBySchematIds(schematIds: number[]) {
    if (!data.value) {
      return [];
    }

    return data.value.filter((schemat) => schematIds.includes(schemat.id));
  }

  return {
    data,
    searchedSchemats,
    search,
    activeSchemats,
    getOptionsBySchematIds,
  };
}
